* {
	box-sizing: border-box;
}

.timeline {
	position: relative;
	padding-block: var(--timeline-padding);
	--timeline-color: var(--color-accent);
	--timeline-point-color: var(--color-accent);
	--timeline-point-color-active: white;
	--timeline-height: 100px;
	--timeline-padding: calc(var(--spacing) * 2);
	--timeline-point-size: 60px;
	--timeline-point-font-size: 0.85em;
	--timeline-width: 4px;

	@media screen and (max-width: 700px) {
		--timeline-padding: var(--spacing);
		--timeline-point-size: 48px;
		--timeline-point-font-size: 0.725em;
	}
}

.timeline::before {
	transition: all 0.3s ease-out;
	position: absolute;
	left: calc(var(--timeline-point-size) / 2 - var(--timeline-width) / 2);
	width: var(--timeline-width);
	height: var(--timeline-height);
	max-height: 100%;
	content: '';
	border-radius: calc(var(--timeline-width) * 2);
	background-color: var(--timeline-color);
}

.timeline-item {
	position: relative;
	padding-left: calc(var(--timeline-point-size) + var(--timeline-padding));
}

.timeline-item > .timeline-content {
	padding-bottom: var(--timeline-padding);
	transition: all 0.3s ease-in-out;
	transform: scale(0.8) translateX(10%);
	opacity: 0;
	filter: blur(4px);
}

.timeline-item-active > .timeline-content {
	transform: none !important;
	opacity: 1;
	filter: none;
}

.timeline-item > .timeline-point {
	font-size: var(--timeline-point-font-size);
	font-weight: 600;
	letter-spacing: -0.25px;
	position: absolute;
	left: 0;
	display: grid;
	place-items: center;
	padding-top: 1px;
	overflow: hidden;
	width: var(--timeline-point-size);
	height: var(--timeline-point-size);
	transition: all 0.3s ease-in-out;
	text-align: center;
	color: var(--timeline-point-color);
	border: var(--timeline-width) solid var(--timeline-color);
	border-radius: 50%;
	user-select: none;
}

.timeline-item-active > .timeline-point {
	color: var(--timeline-point-color-active);
	background: var(--timeline-color);
}

@media screen and (min-width: 768px) {
	.timeline::before {
		left: 50%;
		transform: translateX(-50%);
	}

	.timeline-item:nth-child(even) {
		padding-right: calc(50% + var(--timeline-point-size) / 2 + var(--timeline-padding));
		padding-left: 0;
	}

	.timeline-item:nth-child(even) > .timeline-content {
		transform: scale(0.8) translateX(-10%);
	}

	.timeline-item:nth-child(odd) {
		padding-left: calc(50% + var(--timeline-point-size) / 2 + var(--timeline-padding));
	}

	.timeline-item > .timeline-point {
		left: 50%;
		transform: translateX(-50%);
	}
}
