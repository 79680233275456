.social-grid {
	display: flex;
	flex-direction: row;
	gap: var(--spacing);
	justify-content: center;

	@media screen and (max-width: 700px) {
		flex-direction: column;
	}

	> a {
		z-index: 1;
		display: flex;
		align-items: center;
		flex-direction: column;
		transition: background-color 0.3s ease-in-out;
		text-decoration: none;
		color: var(--color-text);
		border-radius: var(--border-radius);
		background: rgba(255, 255, 255, 0.05);
		padding-inline: calc(var(--spacing) * 1.5);
		padding-top: var(--spacing);

		@media screen and (max-width: 700px) {
			flex-direction: row;
			gap: calc(var(--spacing) * 1.5);
			padding-top: 0;
		}

		i {
			min-width: 40px;
			width: 40px;
			height: 40px;
			display: grid;
			place-items: center;
		}

		span.subtitle {
			font-size: 0.8rem;
			font-weight: 300;
			opacity: 0.8;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.1);
		}
	}
}
