.skills {
	position: relative;

	.skills-container {
		> table {
			border-collapse: collapse;
			width: 100%;
		}

		> table {
			tr:nth-child(2n - 1) {
				background: rgba(255, 255, 255, 0.05);
			}

			tr td {
				padding: 16px 0;

				&:first-child {
					border-radius: 10px 0 0 10px;
					padding: 16px;
					text-align: center;
				}

				&:nth-child(2) {
					display: flex;
					flex-direction: column;
					gap: 2px;
				}

				&:last-child {
					border-radius: 0 10px 10px 0;
					padding: 24px;
					width: 100%;
				}

				span {
					white-space: nowrap;

					&:first-child {
						font-weight: 800;
					}

					&:nth-child(2) {
						font-weight: 300;
						font-size: 0.8rem;
						opacity: 0.8;
					}
				}
			}
		}
	}

	.skill-progress {
		height: 8px;
		position: relative;
	}

	.skill-progress::before {
		background: var(--color-accent);
		border-radius: 4px;
		content: "";
		height: 100%;
		position: absolute;
		transition: width 500ms ease;
		width: 0;
	}

	.skill-progress.show::before {
		width: 100%;
	}

	.skills-container-lower {
		align-items: center;
		display: flex;
		flex-direction: row !important;
		justify-content: space-around;
	}

	@media screen and (max-width: 700px) {
		.skills-container-lower {
			display: grid !important;
			gap: 50px;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: repeat(2, 1fr);
			justify-items: center;
		}
	}
}