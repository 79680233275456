.work-experience {
	margin-top: calc(var(--spacing) * 4);
	overflow: hidden;

	.work-experience-timeline {
		min-height: 4px;
		min-width: 100px;
		height: 4px;
		width: 100%;
		background: var(--color-accent);
		border-radius: var(--border-radius);
	}

	.work-experience-companies {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		position: relative;

		.work-experience-company {
			background: rgba(255, 255, 255, 0.05);
			padding: 0 calc(var(--spacing) * 2) var(--spacing) calc(var(--spacing) * 2);
			border-radius: 0 0 var(--border-radius) var(--border-radius);
			text-align: center;

			&::before {
				content: "";
				position: absolute;
				height: 40px;
				width: 40px;
				background: var(--color-accent);
				top: -20px;
				left: 50%;
				transform: translateX(-50%) rotateZ(45deg);
			}

			img {
				margin-top: calc(var(--spacing) * 2 + 28px);

				height: 100px;
				width: 100%;
				object-fit: contain;
			}

			.heading {
				margin-block: 0 calc(var(--spacing) * -0.5);
			}
		}
	}
}
