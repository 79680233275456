nav.navigation {
	--navigation-underline-border: 2px solid var(--color-text);

	> div.navigation-menu {
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;

		text-wrap: nowrap;
		user-select: none;

		width: 100%;

		> .navigation-items {
			height: 100%;
			width: fit-content;
			margin-top: -2px;
			margin-inline: auto;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: calc(var(--spacing) / 2);

			padding: calc(var(--spacing) / 2);
			background: black;
			border: var(--navigation-underline-border);
			border-radius: 0 0 var(--border-radius) var(--border-radius);

			> .navigation-item {
				color: var(--color-text);
				border-radius: var(--border-radius);
				padding: 4px 8px;
				font-size: 0.9em;
				text-decoration: none;
				transition: all 0.3s ease-in-out;
				text-align: center;

				&:hover {
					background: rgba(255, 255, 255, 0.1);
				}
			}
		}

		> .underline {
			position: absolute;
			right: 0;
			top: calc(var(--spacing) / 2 - 2px);
			// transform: translateY(-50%);
			width: 100%;
			height: 10px;
			border: var(--navigation-underline-border);
			transition: all 0.3s ease-in-out;
			border-radius: var(--border-radius);
			pointer-events: none;
			opacity: 0;
		}
	}

	@media screen and (max-width: 700px) {
		> div.navigation-menu {
			transition: all 0.3s ease-in-out;

			top: auto;
			bottom: calc(var(--spacing) * 2 + 80px);

			opacity: 0;
			pointer-events: none;

			> .navigation-items {
				transition: inherit;

				flex-direction: column;
				padding: var(--spacing);
				gap: var(--spacing);

				border-radius: var(--border-radius) !important;

				margin-right: -50%;

				> .navigation-item {
					font-size: 1.2em;
				}
			}

			> .underline {
				display: none !important;
			}
		}

		&.active > div.navigation-menu {
			opacity: 1;
			pointer-events: all;

			> .navigation-items {
				margin-right: var(--spacing);
			}
		}

		> .navigation-blob {
			display: grid !important;
		}
	}

	> .navigation-blob {
		position: fixed;
		z-index: 1000;
		bottom: var(--spacing);
		right: var(--spacing);

		height: 80px;
		width: 80px;
		background: var(--color-accent);
		border-radius: 50%;

		padding: 20px 10px;
		display: none;
		place-items: center;

		.navigation-blob-bar {
			height: 4px;
			width: 40px;
			border-radius: var(--border-radius);
			background: var(--color-text);

			transition: all 0.3s ease-in-out;
		}
	}

	&.active > .navigation-blob {
		border-radius: 25%;

		.navigation-blob-bar:nth-child(1) {
			transform: translateY(13px) rotate(45deg);
		}

		.navigation-blob-bar:nth-child(2) {
			opacity: 0;
		}

		.navigation-blob-bar:nth-child(3) {
			transform: translateY(-13px) rotate(-45deg);
		}
	}
}
