.education {
	--tile-background: rgba(255, 255, 255, 0.05);

	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: calc(var(--spacing) * 4);
	margin-top: calc(var(--spacing) * 4);

	.education-tile {
		display: flex;
		flex-direction: row;
		gap: calc(var(--spacing) * 2);
		border-radius: var(--border-radius);

		.education-tile-logo {
			img {
				width: 240px;
				object-fit: contain;

				padding: var(--spacing);

				background: white;
				border-radius: var(--border-radius);
			}
		}

		.education-tile-content {
			flex: 1;
			background: var(--tile-background);
			border-radius: var(--border-radius);
			padding: var(--spacing) calc(var(--spacing) * 2);

			.heading {
				margin-bottom: calc(var(--spacing) / 1.5);
			}

			.caption-row {
				margin-bottom: calc(var(--spacing) * 2);
			}

			.education-tile-links {
				display: flex;
				gap: var(--spacing);
				margin-top: calc(var(--spacing) * 1);
				margin-bottom: calc(var(--spacing) * 1);

				a {
					transition: background-color 0.3s ease-in-out;

					padding: calc(var(--spacing) * 0.5) var(--spacing);
					background: rgba(255, 255, 255, 0.05);
					color: var(--color-text);
					text-decoration: none;
					border-radius: var(--border-radius);

					i {
						margin-right: 8px;
					}

					&:hover {
						background: rgba(255, 255, 255, 0.1);
					}
				}
			}

			.education-tile-hashtags {
				display: inline-flex;
				flex-direction: row;
				gap: calc(var(--spacing) / 2);
				flex-wrap: wrap;
				color: #a573dc;
			}
		}

		.heading {
			margin-top: 0;
		}
	}

	@media screen and (max-width: 700px) {
		.education-tile {
			flex-direction: column;
			gap: 0;
			background: var(--tile-background);

			.education-tile-logo {
				padding-left: calc(var(--spacing) * 2);
				padding-top: calc(var(--spacing) * 2);
				padding-right: calc(var(--spacing) * 2);

				img {
					width: 100%;
				}
			}

			.education-tile-content {
				background: transparent;
			}
		}
	}
}
